import {StoryblokComponent, storyblokEditable} from '@storyblok/react'

export default ({ blok }) => {
    return (
        <section className="section-full" {...storyblokEditable(blok)}>
            <div className="discover">
                <h1 className="discover-title">{blok.title}</h1>
                <ul className="discover-categories">
                    {blok.tags.map((tag) => (
                        <StoryblokComponent blok={tag} key={tag._uid} />
                    ))}
                </ul>
            </div>
        </section>
    )
}
