import { storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <div className="gray-block" {...storyblokEditable(blok)}>
            <h1 className="gray-subtitle">{blok.title}</h1>
            <p className="gray-text">{blok.text}</p>
        </div>
    )
}
