import {StoryblokComponent, storyblokEditable} from '@storyblok/react'

export default ({ blok }) => {
    return (
        <section className="glasses-slider activity multiSlider" {...storyblokEditable(blok)}>
            <div className="home-slider shop-activity-slider">
                <div className="home-slider-top">
                    <h1 className="home-slider-subtitle">{blok.title || 'Shop by Activity'}</h1>
                    <div className="home-slider-control multiSliderControl">
                        <button className="home-slider-control-btn prev" disabled>
                            <svg>
                                <use xlinkHref="#slider-prev-black"></use>
                            </svg>
                            <svg>
                                <use xlinkHref="#slider-prev"></use>
                            </svg>
                        </button>
                        <button className="home-slider-control-btn next">
                            <svg>
                                <use xlinkHref="#slider-next-black"></use>
                            </svg>
                            <svg>
                                <use xlinkHref="#slider-next"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="shop-activity multiSlides">
                    {blok.activities.map((blok) => (
                        <StoryblokComponent blok={blok} key={blok._uid} />
                    ))}
                </div>
                <div className="home-slider-indicator"></div>
            </div>
        </section>
    )
}
