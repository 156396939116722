import {storyblokEditable} from '@storyblok/react'

const CategoryBlock = ({blok}) => {
	let classes = {
		li: "home-blog-item",
		imgWrapper: "home-blog-img",
		info: "home-blog-info",
		title: "home-blog-info-title",
		text: "home-blog-info-text",
		btns: "home-blog-info-btns",
		btn: "home-blog-info-btn"
	}

	if (blok.type === 'style') {
		classes = {
			li: "home-blog-item style-item",
			imgWrapper: "home-blog-img style-img",
			info: "home-blog-info style-info",
			title: "home-blog-info-title style-info-title",
			text: "home-blog-info-text style-info-text",
			btns: "home-blog-info-btns style-info-btns",
			btn: "style-info-btn"
		}
	}
	const getImage = () => {
		return (
			<>
				{blok.mobile_image && blok.mobile_image.filename && <img width="100" rel="preload" loading="lazy" className="style-mobile-img" src={blok.mobile_image.filename} alt={blok.image.alt}/>}
				<img  width="100" loading="lazy" rel="preload" src={blok.image.filename} alt={blok.image.alt}/>
			</>
		)
	}

	return (
		<li className={classes.li} {...storyblokEditable(blok)}>
			{blok.image_link ? (
				<a href={blok.image_link.url} className={classes.imgWrapper}>
					{getImage()}
				</a>
			) : (
				<div className={classes.imgWrapper}>
					{getImage()}
				</div>
			)}
			<div className={classes.info}>
				{blok.subtitle && (
					<span className="home-blog-info-subtitle">{blok.subtitle}</span>
				)}
				<h1 className={classes.title}>{blok.title}</h1>
				<p className={classes.text}>
					{blok.description}
				</p>
				<div className={classes.btns}>
					{blok.links.map((link, i) => (
						<a key={i} href={link.link.url} className={classes.btn}>
							{link.title}
						</a>
					))}
				</div>
			</div>
		</li>
	);
};

export default CategoryBlock;
