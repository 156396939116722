export default ({ blok }) => {
		let className = '';
		if(blok.title == 'Virtual Try-On')
			className = 'auglio-tryon-btn';
    return (
        <section className={"banner-block " + blok.class}>
	        <div className="section-full">
		        <div className="banner-block-cont">
			        {blok.class === 'cartier' && (
				        <h1 className="banner-block-title">
					        <img src="/img/Cartier.svg" alt=""/>
				        </h1>
			        )}
			        {blok.youtube ? (
				        <div className="banner-block-video">
					        <iframe src={blok.youtube}></iframe>
				        </div>
			        ) : (
								blok.image_link ? (
									<a href={blok.image_link.url} className="banner-block-img">
										{(blok['mobile_background'] && blok['mobile_background']['filename']) ? <img rel="preload" src={blok['mobile_background'].filename} alt={blok['mobile_background'].alt} className="banner-block-img-mobile"/> : null}
										<img rel="preload" src={blok.background.filename} alt={blok.background.alt}/>
									</a>
								) : (
									<div className="banner-block-img">
										{(blok['mobile_background'] && blok['mobile_background']['filename']) ?
											<img src={blok['mobile_background'].filename} alt={blok['mobile_background'].alt}
											     className="banner-block-img-mobile"/> : null}
										<img src={blok.background.filename} alt={blok.background.alt}/>
									</div>
								)
			        )}
		        </div>
		        <div className="banner-block-info">
			        <h1 className="banner-block-info-title">{blok.title}</h1>
			        <p className="banner-block-info-text">{blok.description}</p>
			        <div className="banner-block-info-btns">
				        {blok.links.map((link) => (
					        <a key={link._uid} href={link.link.url}
					           className={className+" banner-block-info-btn"}>
						        {link.title}
					        </a>
				        ))}
			        </div>
		        </div>
	        </div>
        </section>
    )
}
