import { storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <div className="section lg:!px-16"
             {...storyblokEditable(blok)}
        >
            <div className="pt-24">
                <h1 className="text-center lg:text-left text-2.5xl lg:text-[25px] leading-tight mb-4">{blok.title}</h1>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-10">
                    {blok.staff.map(staff => (
                        <div key={staff._uid} className="py-6">
                            <img className="block mb-4 max-w-full lg:max-w-none mx-auto lg:w-full h-auto" alt={staff.photo.alt} src={staff.photo.filename} />
                            <div className="mb-6 lg:mb-7">
                                <h1 className="text-2xl font-medium mb-1">{staff.name}</h1>
                                <strong className="text-base text-neutral-500">{staff.title}</strong>
                            </div>

                            <div className="text-900 text-sm lg:text-base" dangerouslySetInnerHTML={{__html: staff.bio.replace(/\n/g, '<br>')}}></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
