import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <section className="gray" {...storyblokEditable(blok)}>
            <h1 className="gray-title">{blok.title}</h1>

            <div className="gray-list">
                {blok.columns.map((column) => (
                    <StoryblokComponent blok={column} key={column._uid} />
                ))}
            </div>
        </section>
    )
}
